@import '../../Common/Styles/variables';
@import '../../Common/Styles/mixins';

.notFoundPage, .unauthorizedAccessPage {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;

  .notFoundImg, .unauthorizedImg {
    max-width: 100%;
    height: auto;
    padding-left: pxToRem(8px);
    padding-right: pxToRem(8px);
  }

  .pageHeadLine {
    @include font-size-30;
    color: $color-gray-1;
    font-weight: 400;
    margin-bottom: pxToRem(21px);
  }

  .warningMessage {
    color: $color-1;
    font-size: pxToRem(16px);
    padding-top: pxToRem(32px);
    padding-bottom: pxToRem(32px);
  }

  .backToButton {
    background-color: $color-1;
    padding: pxToRem(10px) pxToRem(31px);
    color: $whiteColor;
    font-size: pxToRem(16px);
    font-weight: bold;
    border-radius: pxToRem(2px);
    border: 0;
  }
}

