@import "variables";

// Transform pixels to rems
@function pxToRem($value){
  @return ($value / $defaultFontSize) + rem
}

%label {
  font-size: pxToRem(14px);
  line-height: pxToRem(20px);
  color: $color-gray-1;
  font-weight: 400;
  margin-bottom: pxToRem(10px);
  //margin-bottom: pxToRem(20px);
}

%input {
  width: 100%;
  border: pxToRem(1px) solid $borderColor;
  border-radius: pxToRem(4px);
  padding: pxToRem(8px) pxToRem(30px) pxToRem(8px) pxToRem(8px);
  font-size: pxToRem(14px);
  color: $color-1;
  background-color: #ffffff;
}

%ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin font-size-14 {
  font-size: pxToRem(14px);
  line-height: pxToRem(20px);
}

@mixin font-size-18 {
  font-size: pxToRem(18px);
  line-height: pxToRem(24px);
}

@mixin font-size-24 {
  font-size: pxToRem(24px);
  line-height: pxToRem(30px);
}

@mixin font-size-30 {
  font-size: pxToRem(30px);
  line-height: pxToRem(36px);
}

@mixin font-size-40 {
  font-size: pxToRem(40px);
  line-height: pxToRem(52px);
}

@mixin font-size-46 {
  font-size: pxToRem(46px);
  line-height: pxToRem(52px);
}
