//Fonts
$mainFontFamily: 'Siemens Sans', sans-serif;
//Font sizes
$defaultFontSize: 16px;
//Colors
$color-1: #2D373C;
$color-2: #005578;
$color-3: #2387AA;

$color-gray-1: #697882;

$button-color-1: #F08100;

$backgroundColor: #EBF0F5;

$whiteColor: #FFFFFF;

$lightBackgroundColor: #F4F7FF;
$borderColor: #CDD9E1;

$dangerColor: #C31F1F;

$themeColors: () !default;
$themeColors: map-merge(
  (
    "accent": $button-color-1,
    "danger": $dangerColor
  ),
  $themeColors
);

// Bootstrap overrides
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
      0: 0,
      1: ($spacer * .25),
      2: ($spacer * .5),
      3: $spacer,
      4: ($spacer * 1.5),
      5: ($spacer * 1.75),
      6: ($spacer * 2),
      7: ($spacer * 3)
  ),
  $spacers
);

$eventHeaderHeight: 105px
