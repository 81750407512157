@import "variables";

:root {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root, body, html {
  background-color: $backgroundColor;
}

body {
  margin: 0;
  padding: 0;
  font-family: $mainFontFamily;
  font-size: 0.875rem;
  color: $color-1;
}

button {
  font-family: $mainFontFamily;
}

//* {
//  box-sizing: border-box;
//
//  &::-webkit-scrollbar {
//    width: 8px;
//    background-color: transparent;
//  }
//
//  &::-webkit-scrollbar-thumb {
//    border-radius: 4px;
//    background-color: transparentize($Slate900, 0.6);
//  }
//}

h1, h2, h3, h4, h5 {
  margin: 0;
}

//a {
//  text-decoration: none;
//  color: $LinkColor;
//
//  &:hover, &:focus {
//    outline: none;
//    color: darken($LinkColor, 3);
//  }
//}

* {
  &:focus, &:active {
    outline: none;
  }
}

p {
  margin-top: 0;
}
