@import "variables";
@import "mixins";

// Font size util classes
.fs-14 {
  @include font-size-14;
}

.fs-18 {
  @include font-size-18;
}

.fs-24 {
  @include font-size-24;
}

.fs-30 {
  @include font-size-30;
}

.fs-40 {
  @include font-size-40;
}

.color-1 {
  color: $color-1;
}

//.colorTertiary {
//  color: $tertiaryColor;
//}

.cursorPointer {
  cursor: pointer;
}

.cursorNotAllowed {
  cursor: not-allowed;
}

.spacingNormal {
  letter-spacing: normal !important;
}
