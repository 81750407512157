@font-face{
	font-family: "siemens-webicons-webfont";
	src: url("../Assets/Fonts/siemens-webicons-webfont.woff") format("woff"),
  url("../Assets/Fonts/siemens-webicons-webfont.svg") format("svg");
}

[class^=icon],[class*=" icon"],[class^=link-transform-icon],[class*=" link-transform-icon"]{line-height:inherit;text-decoration:none;text-transform:none}
[class^=icon]:before,[class*=" icon"]:before,[class^=link-transform-icon]:before,[class*=" link-transform-icon"]:before{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-family:"siemens-webicons-webfont",sans-serif;font-style:normal;font-weight:normal;font-variant:normal;padding-right:6px;position:relative;vertical-align:middle}
.icon-apps:before{content:""}
.icon-arrow-down-large:before{content:""}
.icon-arrow-left-large:before{content:""}
.icon-arrow-right-large:before{content:""}
.icon-arrow-up-large:before{content:""}
.icon-arrow-down-small:before{content:""}
.icon-arrow-left-small:before{content:""}
.icon-arrow-right-small:before{content:""}
.icon-arrow-up-small:before{content:""}
.icon-arrow-to-top:before{content:""}
.icon-at:before{content:""}
.icon-bookmark:before{content:""}
.icon-calculate:before{content:""}
.icon-calendar:before{content:""}
.icon-chat:before{content:""}
.icon-check-mark:before{content:""}
.icon-circle:before{content:""}
.icon-close:before{content:""}
.icon-control:before{content:""}
.icon-delete:before{content:""}
.icon-double-arrow-backward:before{content:""}
.icon-double-arrow-down:before{content:""}
.icon-double-arrow-forward:before{content:""}
.icon-double-arrow-up:before{content:""}
.icon-download:before{content:""}
.icon-drag:before{content:""}
.icon-edit:before{content:""}
.icon-email:before{content:""}
.icon-ext:before{content:""}
.icon-facebook:before{content:""}
.icon-facebook-negative:before{content:""}
.icon-fax:before{content:""}
.icon-file:before{content:""}
.icon-avi:before{content:""}
.icon-doc:before{content:""}
.icon-eps:before{content:""}
.icon-jpg:before{content:""}
.icon-mov:before{content:""}
.icon-mp3:before{content:""}
.icon-mp4:before{content:""}
.icon-pdf:before{content:""}
.icon-png:before{content:""}
.icon-ppt:before{content:""}
.icon-files:before{content:""}
.icon-tif:before{content:""}
.icon-vcf:before{content:""}
.icon-xls:before{content:""}
.icon-zip:before{content:""}
.icon-forum:before{content:""}
.icon-fullscreen:before{content:""}
.icon-globe:before{content:""}
.icon-glossary:before{content:""}
.icon-google:before{content:""}
.icon-google-negative:before{content:""}
.icon-home:before{content:""}
.icon-information:before{content:""}
.icon-information-package:before{content:""}
.icon-instagram:before{content:""}
.icon-instagram-negative:before{content:""}
.icon-legal-notice:before{content:""}
.icon-level-up:before{content:""}
.icon-linkedin:before{content:""}
.icon-linkedin-negative:before{content:""}
.icon-list-view:before{content:""}
.icon-location-pin:before{content:""}
.icon-log-in:before{content:""}
.icon-log-out:before{content:""}
.icon-menu:before{content:""}
.icon-minus:before{content:""}
.icon-more:before{content:""}
.icon-navigation-gyroscope:before{content:""}
.icon-navigation-mouse:before{content:""}
.icon-newton_product-catalogue:before{content:""}
.icon-outline:before{content:""}
.icon-phone:before{content:""}
.icon-play:before{content:""}
.icon-plus:before{content:""}
.icon-print:before{content:""}
.icon-rating:before{content:""}
.icon-region:before{content:""}
.icon-rss:before{content:""}
.icon-scaling:before{content:""}
.icon-search:before{content:""}
.icon-share-ios:before{content:""}
.icon-share-newton-android:before{content:""}
.icon-shopping-cart:before{content:""}
.icon-show-location:before{content:""}
.icon-single-arrow-backward:before{content:""}
.icon-single-arrow-down:before{content:""}
.icon-single-arrow-forward:before{content:""}
.icon-single-arrow-up:before{content:""}
.icon-slider:before{content:""}
.icon-social-feed:before{content:""}
.icon-audio-on:before{content:""}
.icon-subscribe:before{content:""}
.icon-theenergyblog:before{content:""}
.icon-theenergyblog-negative:before{content:""}
.icon-tile-view:before{content:""}
.icon-twitter:before{content:""}
.icon-twitter-negative:before{content:""}
.icon-user-account:before{content:""}
.icon-video-chat:before{content:""}
.icon-warning:before{content:""}
.icon-youtube:before{content:""}
.icon-youtube-negative:before{content:""}
.icon-zoom:before{content:""}
.icon-weibo:before{content:""}
.icon-weibo-negative:before{content:""}
.icon-youku:before{content:""}
.icon-youku-negative:before{content:""}
.icon-support:before{content:""}
.icon-service:before{content:""}
.icon-career:before{content:""}
.icon-clock:before{content:""}
.icon-conference:before{content:""}
.icon-filter:before{content:""}
.icon-investor-relations:before{content:""}
.icon-press:before{content:""}
.icon-question:before{content:""}
.icon-quotation:before{content:""}
.icon-rectification:before{content:""}
.icon-settings:before{content:""}
.icon-tickets:before{content:""}
.icon-trade-fair:before{content:""}
.icon-webinar:before{content:""}
.icon-aparat-negative:before{content:""}
.icon-aparat:before{content:""}
.icon-tumblr-negative:before{content:""}
.icon-tumblr:before{content:""}
.icon-360-feature:before{content:""}
.icon-fast-backward:before{content:""}
.icon-fast-forward:before{content:""}
.icon-pause:before{content:""}
.icon-replay:before{content:""}
.icon-skip-backward:before{content:""}
.icon-skip-forward:before{content:""}
.icon-stop:before{content:""}
.icon-audio-down:before{content:""}
.icon-audio-up:before{content:""}
.icon-windows-app-store:before{content:""}
.icon-apple-mac-app-store:before{content:""}
.icon-android-app-store:before{content:""}
.icon-shuffle:before{content:""}
.icon-biography:before{content:""}
.icon-embed:before{content:""}
.icon-facts-and-forecasts:before{content:""}
.icon-infographic:before{content:""}
.icon-renren-negative:before{content:""}
.icon-renren:before{content:""}
.icon-webcast:before{content:""}
.icon-weixin-negative:before{content:""}
.icon-weixin:before{content:""}
.icon-add-to-download-basket:before{content:""}
.icon-audio-off:before{content:""}
.icon-download-basket:before{content:""}
.icon-gif:before{content:""}
.icon-audio:before{content:""}
.icon-feature:before{content:""}
.icon-footage:before{content:""}
.icon-glassdoor-negative:before{content:""}
.icon-glassdoor:before{content:""}
.icon-picture-set:before{content:""}
.icon-picture:before{content:""}
.icon-press-release:before{content:""}
.icon-videos:before{content:""}
.icon-slideshow:before{content:""}
.icon-presentation:before{content:""}
.link-transform-icon-arrow-right-small:before{content:""}
.link-transform-icon-ext:before{content:""}
.link-transform-icon-pdf:before{content:""}
.link-transform-icon-email:before{content:""}
.link-transform-icon-theenergyblog:before{content:""}
.link-transform-icon-facebook:before{content:""}
.link-transform-icon-twitter:before{content:""}
.link-transform-icon-linkedin:before{content:""}
.link-transform-icon-youtube:before{content:""}
.link-transform-icon-instagram:before{content:""}
.link-transform-icon-phone:before{content:""}
.link-transform-icon-youku:before{content:""}
.link-transform-icon-weixin:before{content:""}
.link-transform-icon-weibo:before{content:""}
.link-transform-icon-tumblr:before{content:""}
.link-transform-icon-google:before{content:""}
