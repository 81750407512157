@font-face {
    font-family: 'Siemens Sans';
    src: url('../Assets/Fonts/siemenssans-roman-webfont.woff2') format('woff2'),
        url('../Assets/Fonts/siemenssans-roman-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Siemens Sans';
    src: url('../Assets/Fonts/siemenssans-bold-webfont.woff2') format('woff2'),
        url('../Assets/Fonts/siemenssans-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

