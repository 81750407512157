@import "variables";
@import "mixins";

html, body, #root {
  height: 100%;
}

.redux-toastr .toastr {
  z-index: 1312312;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pages {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: pxToRem(30px);
}

a {
  &.siemens {
    color: $color-3;
  }
}

.table-responsive {
  padding: pxToRem(1px);
}

table.table {
  th, td {
    vertical-align: middle;
    padding: pxToRem(20px);
  }

  thead {
    th {
      @include font-size-14;
      font-weight: normal;
      color: $color-gray-1;
      border-top: 0;
      border-bottom: 0;
      white-space: nowrap;
    }
  }

  tbody {
    tr {
      &:last-child {
        td {
          border-bottom: pxToRem(1px) solid transparent;
        }
      }

      &:hover {
        background-color: $whiteColor;

        td {
          &:first-child {
            border-left: pxToRem(1px) solid $borderColor;
          }

          &:last-child {
            border-right: pxToRem(1px) solid $borderColor;
          }
        }

        &:last-child {
          td {
            border-bottom: pxToRem(1px) solid $borderColor;
          }
        }
      }

      td {
        @include font-size-14;
        font-weight: normal;
        color: $color-1;
        white-space: nowrap;
        border-top: pxToRem(1px) solid $borderColor;

        &:first-child {
          border-left: pxToRem(1px) solid transparent;
        }

        &:last-child {
          border-right: pxToRem(1px) solid transparent;
        }
      }
    }
  }
}
