.inputWrapper {
  margin-top: pxToRem(20px);
  //margin-top: pxToRem(30px);

  label {
    @extend %label;
  }

  input {
    @extend %input;
  }
}

.inputError {
  margin-top: pxToRem(10px);
  background: $dangerColor;
  padding: pxToRem(8px);
  color: $whiteColor;
}
//.inputWrapper {
//  display: flex;
//  align-items: center;
//
//  &.form-group {
//    margin-bottom: pxToRem(12px);
//  }
//
//  label, .label {
//    color: $tertiaryColor;
//    font-weight: 500;
//    font-size: pxToRem(16px);
//    margin: 0;
//    padding-top: pxToRem(6px);
//    padding-bottom: pxToRem(6px);
//    align-self: flex-start;
//  }
//
//  input, textarea, .input {
//    border: pxToRem(1px) solid $borderColor;
//    border-radius: pxToRem(2px);
//    width: 100%;
//    padding: pxToRem(6px) pxToRem(10px);
//    //caret-color: #F99400;
//    color: $primaryColor;
//
//    &.hasError {
//      border: 1px solid $errorColor;
//    }
//
//    &:focus {
//      &.hasError {
//        border: 1px solid $errorColor;
//      }
//
//      border-color: $swarmColor;
//      outline: none;
//      box-shadow: none;
//    }
//
//    &::placeholder, .placeholder {
//      color: $quaternaryColor;
//      font-weight: normal;
//      font-size: pxToRem(16px);
//      opacity: 0.7;
//    }
//  }
//
//  textarea {
//    height: pxToRem(144px);
//  }
//
//  input, .input {
//    background-color: $whiteColor;
//    height: pxToRem(36px);
//  }
//}
//
//.disabled,
//input:disabled,
//textarea:disabled {
//  cursor: not-allowed !important;
//  opacity: 0.5 !important;
//}
