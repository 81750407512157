@import "../../Common/Styles/variables";
@import "../../Common/Styles/mixins";

.helpAndSupportModal {
  a.siemens {
    font-weight: 500;
    font-size: pxToRem(16px);
    word-break: break-word;
  }

  .actionButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: pxToRem(24px);

    button {
      width: 100%;
    }
  }
}
